/*
 * Base: Ellanse - Themes
 * This is used to apply classes to rows in the grid, which will define a theme for all grid elements in that row.
 * An example of this is to add a blue theme, and then the quote will have a different background.
 */

.form {
  & .form-group {
    select {
      border-color: var(--color-grey-light);
      background: var(--color-white);

      &:hover {
        border-color: var(--color-grey);
      }
      &:focus {
        border-color: var(--color-grey);
      }
    }
  }
  &-field--error.input {
    background-color: var(--color-grey-light) !important;
    border-color: var(--color-warning) !important;
  }
}

.theme-ellanse-pale {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-pale-yellow);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-grey-light);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-grey-light) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-grey {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-grey-mid);
    z-index: -1;
  }
}

.theme-ellanse-onyx-grey {
  position: relative;

  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-onyx-grey);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-onyx-grey);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-dune-gold {
  position: relative;

  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-dune-gold);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-dune-gold);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-floral-blush {
  position: relative;

  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-floral-blush);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-floral-blush);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-oaken-bronze {
  position: relative;

  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-oaken-bronze);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-oaken-bronze);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-dusky-honey {
  position: relative;

  color: var(--color-white);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-dusky-honey);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-dusky-honey);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-light-grey {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-grey-light);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-grey-light);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

.theme-ellanse-gradient {
  position: relative;

  --color-text: var(--color-black);

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--site-max-width);
    max-width: 100vw;
    height: 100%;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
    background: var(--color-background-gradient);
    z-index: -1;
  }

  .form {
    & .form-group {
      & input,
      textarea,
      .input {
        border-color: var(--color-grey-light);
        background: var(--color-white);

        &:hover {
          border-color: var(--color-grey);
        }
        &:focus {
          border-color: var(--color-grey);
        }
      }
    }
    &-field--error.input {
      background-color: var(--color-validation-error) !important;
      border-color: var(--color-warning) !important;
    }
  }
}

/* This creates the swirls, if the theme-branded is on the .row */
.theme-branded {
  position: relative;

  .ellanse-swirl {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1920px;
    max-width: calc(100vw + 10px);
    transform: rotate(180deg) translate3d(50%, -1%, 0);
    overflow: hidden;
    z-index: 1;
    pointer-events: none;

    & svg {
      position: relative;
      display: block;
      width: calc(134% + 1.3px);
      @media (--viewport-ms-max) {
        height: 40px;
      }
      @media (--viewport-sm-min) {
        height: 70px;
      }
      @media (--viewport-md-min) {
        height: 100px;
      }
    }
    & .shape-fill {
      fill: var(--color-dusky-honey);
    }
    &--top {
      top: 0;
      bottom: unset;
      transform: rotate(0deg) translate3d(-50%, -1%, 0);
    }
  }

  .social-profiles {
    margin-top: 70px;
  }

  .how-it-works {
    &-content {
      &__title {
        @media (--viewport-md-min) {
          margin-top: 80px;
        }
      }
    }
  }

  .clinic-finder-banner {
    &-content {
      &__title {
        @media (--viewport-md-min) {
          margin: 80px 0 25px;
        }
      }
    }
  }

  .rte h2 {
    padding: 64px 0;
    font-weight: 300;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--color-floral-blush);
      height: 2px;
      width: 140px;
      border-radius: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--color-floral-blush);
      height: 2px;
      width: 140px;
      border-radius: 24px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
  }
}
