/* Overwrites of Instalift for Perfetcha theme */

:root {
  --header-height: 106px;
  --header-height-desktop: 128px;
}

/* interactive patient preview overlay */
body.interactive-patient-preview--open {
  .header {
    @media (--viewport-sm-max) {
      pointer-events: none;
    }
  }
}

/* Logo overwrite */
.logo {
  &__image {
    height: 30px;

    @media (--viewport-md-min) {
      height: 37px;
    }

    &--sinclair {
      height: 80px;

      @media (--viewport-md-min) {
        height: 110px;
      }
    }
  }
}

.footer-other-brands .logo__image {
  height: 30px;
  max-width: 120px;
}

/* Header overwrite */
.header {
  &:not(.header--landing-page, .header--shadow) {
    background: none;
  }

  &__inner {
    padding-top: 28px;
  }

  &--shadow {
    background: color(var(--color-black) a(50%));
  }

  &:not(.header--landing-page) + .header-spacer {
    display: none !important;
  }
}

/* Service navigation overwrite */
.navigation {
  &--service {
    position: relative;
    display: block;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translate3d(-50%, -50%, 0);
      overflow: hidden;
      background: color(var(--color-black) a(80%));
    }
  }
}

/* Global navigation overwrite */
.main-navigation {
  @media (--viewport-sm-max) {
    background: var(--color-black);

    &-list {
      &-item {
        &--active {
          color: var(--color-pink);
        }
      }
    }
  }

  @media (--viewport-md-min) {
    &-list {
      &-item {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 100%;
          left: 0;
          background: var(--color-pink);
          width: 100%;
          height: 3px;
          opacity: 0;
          transition: opacity 0.2s var(--transition);
        }

        &:hover {
          color: var(--color-white);
          &::after {
            opacity: 0.5;
          }
        }

        &--active {
          &::after {
            opacity: 1;
          }
        }

        &__link {
          font-weight: 700;
          color: var(--color-white);
          text-shadow: 0 2px 10px color(var(--color-black) a(50%));
        }

        &-sublist {
          background: color(var(--color-white) a(90%));
          box-shadow: 0 0 10px 10px color(var(--color-white) a(15%));
          z-index: 2;

          &-item {
            color: var(--color-text);

            &:hover {
              color: var(--color-pink);
            }

            &__link {
              font-weight: 500;
              border-bottom: 2px solid color(var(--color-pink) a(25%));
            }
          }
        }
      }
    }
  }
}

/* Mobile menu overwrite */
.mobile-menu-toggle {
  bottom: 0;

  &:before,
  &:after {
    background-color: var(--color-white);
  }

  &__label {
    color: var(--color-white);
    font-size: 0.6875rem; /* 11px */
  }
}

/* Hero overwrite */
.hero {
  color: var(--color-white);

  &-head {
    &-title {
      color: currentColor;
      font-size: 2.75rem; /* 44px */
      line-height: 2.75rem; /* 44px */

      @media (--viewport-sm-min) {
        font-size: 4rem; /* 64px */
        line-height: 4rem; /* 64px */
      }

      .quirk {
        display: table;
        font-family: var(--base-font-family);
        font-style: italic;
        font-weight: 300;
        border: 8px solid var(--color-pink);
        margin-top: 8px;
        padding: 0px 16px 8px 10px;
        text-transform: lowercase;
      }
    }
  }

  &-content {
    &__line {
      background: var(--color-white);
    }

    &__text {
      font-size: 1.5rem; /* 24px */
      line-height: 2.25rem; /* 36px */
      font-weight: 500;

      p {
        font-size: 1.5rem; /* 24px */
        line-height: 2.25rem; /* 36px */
        font-weight: 500;
      }

      ~ .hero-content__buttons {
        margin-top: 40px;
      }
    }
  }
}

/* Button overwrite */
a.button,
.button {
  background: var(--color-secondary);
  border-radius: 0;
  font-size: 0.875rem; /* 14px */
  min-width: 240px;
  transition: all 0.2s var(--transition);
  text-transform: uppercase;
  letter-spacing: 0.1666666667rem; /* 1 */

  &:hover {
    background: color(var(--color-secondary) shade(5%));
  }

  &:before {
    background: none;
  }

  &--secondary {
    background: var(--color-primary);

    &:hover {
      background: color(var(--color-primary) shade(5%));
    }
  }

  &--ghost {
    background: transparent;
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);

    &:hover {
      background: var(--color-secondary);
      color: var(--color-white);
      border-color: var(--color-white);
    }
  }

  &--chip {
    font-size: 0.75rem; /* 12px */
    text-transform: capitalize;
    min-width: auto;
  }
}
