/* Settings */
@import '../_base/_normalize.css';
@import '../_base/_ellanse.variables.css';
@import '../_base/_grid.css';
@import '../_base/_base.css';
@import '../_base/_ellanse.typography.css';

/* Atoms */
@import-glob '../01-atoms/**/*.css';

/* Molecules */
@import-glob '../02-molecules/**/*.css';

/* Organisms */
@import-glob '../03-organisms/**/*.css';

/* Pages */
@import-glob '../04-pages/**/*.css';

/* Themes */
@import '../_base/_ellanse.themes.css';

/* Overwrites of Instalift for Perfetcha theme */
@import './themes/perfectha/perfectha-overwrites.css';

/* Overwrites of Perfectha for LanLuma theme */

.logo__image {
  height: 50px;

  @media (--viewport-md-min) {
    height: 64px;
  }
}

.footer-other-brands .logo__image {
  height: 30px;
  max-width: 120px;
}

.blog-post-card-content {
  &__title {
    font-weight: 700;
  }

  &__cta {
    .icon:first-child {
      display: block;
    }

    span {
      text-transform: none;
      font-size: 1.25rem;
      font-weight: 500;
    }

    .icon:last-child {
      display: none;
    }
  }
}

/* Social media feed overwrites */
.social-media-feed {
  &-content {
    &-list-controls {
      &__input {
        height: 2px;
        background: color(var(--color-white) a(35%));
      }
    }
  }
}

/* Overwrites of Perfectha for Ellanse theme */
a.button,
.button {
  background: var(--color-oaken-bronze);
  border-radius: 24px;
  border: 2px solid transparent;
  color: var(--color-white);

  &:hover {
    border: 2px solid var(--color-oaken-bronze);
    background: var(--color-white);
    color: var(--color-oaken-bronze);
  }

  &--secondary {
    background: transparent;
    color: var(--color-oaken-bronze);
    border: 2px solid var(--color-oaken-bronze);

    @media (--viewport-sm-max) {
      border: 2px solid var(--color-oaken-bronze);
      color: var(--color-white);
      background: color(var(--color-black) a(30%));
    }

    &:hover {
      background: var(--color-oaken-bronze);
      color: var(--color-white);
      border-color: var(--color-oaken-bronze);
    }
  }

  &--ghost {
    border: 2px solid var(--color-oaken-bronze);
    background: var(--color-white);
    color: var(--color-oaken-bronze);

    &:hover {
      background: var(--color-oaken-bronze);
      border-radius: 24px;
      border: 2px solid transparent;
      color: var(--color-white);
    }
  }
}

/* How it works overwrites */
.how-it-works {
  &:before {
    top: 49.8%;
    height: 100.5%;
  }

  &-content {
    &__title {
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-floral-blush);
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }
    &__cta {
      bottom: 55px;
      z-index: 2;
    }

    /* Progress slider */
    &-slides {
      &-controls {
        bottom: 116px;
        &-buttons {
          &__button {
            border-radius: 50%;
          }
        }
        &-progress {
          height: 2px;
          background: color(var(--color-white) a(35%));
        }
      }
      &-item {
        &-inner {
          @media (--viewport-sm-max) {
            bottom: 164px;
            background: color(var(--color-black) a(45%));
            background: linear-gradient(color(var(--color-black) a(45%)) 190px, var(--color-white));
          }
        }
        &-media {
          &:before {
            background-image: none;
          }
        }
        &__caption {
          bottom: 50px;
          @media (--viewport-sm-max) {
            bottom: 30px;
          }
        }
      }
    }
  }
}

/* Video slider overwrites */
.video-slider {
  &__title {
    padding-bottom: 25px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--color-white);
      height: 2px;
      width: 120px;
      border-radius: 24px;
    }
  }

  &-selector {
    position: relative;
    &-progress {
      position: absolute;
      width: 240px;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      background: var(--color-floral-blush);
    }
  }
}

/* Content card listing overwrites */
.content-cards {
  &__title {
    padding-bottom: 25px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: currentColor;
      height: 2px;
      width: 120px;
      border-radius: 24px;
    }
  }
}

/* Profile card listing overwrites */
.profile-cards {
  &__header {
    & h2 {
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: currentColor;
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }
  }
  &__cta {
    z-index: 2;
  }
  &__subtitle {
    font-size: 1rem;

    @media (--viewport-md-min) {
      font-size: 1.3rem;
    }
  }
}

/* Image gallery listing overwrites */
.image-gallery {
  &__header {
    & h2 {
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: currentColor;
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }
  }
  &__cta {
    z-index: 2;
  }
  &__subtitle {
    font-size: 1rem;

    @media (--viewport-md-min) {
      font-size: 1.3rem;
    }
  }
}

/* Profile card */
.profile-card {
  &__name {
    color: var(--color-profile-card-title);
  }
  &__button {
    border-radius: var(--profile-card-button-radius);
  }
}

/* Appetiser overwrites */
.appetiser {
  &-content {
    h2 {
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-white);
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }
  }
}

/* Ask a question overwrites */
.ask-a-question {
  &__title {
    padding-bottom: 25px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--color-floral-blush);
      height: 2px;
      width: 120px;
      border-radius: 24px;
    }
  }
}

/* Quote overwrite */
.quote {
  &-content {
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 140px;
      height: 2px;
      left: 50%;
      background: var(--color-floral-blush);
      transform: translateX(-50%);
      border-radius: 24px;
    }

    &__text {
      margin-top: 64px;
      font-size: 2.0625rem;
      @media (--viewport-ms-max) {
        font-size: 1.7rem;
      }
    }

    &__author {
      text-transform: uppercase;
      margin-bottom: 64px;
      font-size: 1.175rem;
      font-weight: 900;
      margin-top: 40px;
    }
  }

  &-background__image {
    display: none;
  }
}

/* Main navigation */
.main-navigation {
  @media (--viewport-sm-max) {
    &-list {
      &-item {
        &--active {
          color: var(--color-primary);
        }
      }
    }
  }

  @media (--viewport-md-min) {
    &-list {
      &-item {
        padding: 8px 0 22px;
        &::after {
          background: var(--color-primary);
          margin-top: -12px;
        }

        &__link {
          color: var(--color-primary);
          font-weight: 900;

          &:hover {
            color: var(--color-oaken-bronze);
          }
        }

        &-sublist {
          box-shadow: 0 0 10px 10px color(var(--color-black) a(15%));

          &-item {
            color: var(--color-text);

            &:hover {
              color: var(--color-link);
            }

            &__link {
              border-bottom-color: color(var(--color-primary) a(25%));
            }
          }
        }
      }
    }
  }
}

/* Mobile menu overwrite */
.mobile-menu-toggle {
  &:before,
  &:after {
    background-color: var(--color-primary);
  }

  &__label {
    color: var(--color-primary);
  }
}

/* Overwrites of Perfectha for LanLuma theme */
.navigation {
  &--service {
    &:before {
      background: transparent;
    }
  }
  &-list {
    &-item {
      background: transparent;

      &--active {
        &:before {
          top: 0;
          background: var(--color-primary);
        }

        & > a {
          color: var(--color-primary);
        }
      }

      &__link {
        color: var(--color-onyx-grey);
      }
    }
  }
}

/* Site selector color */
.site-selector-toggle__label {
  color: var(--color-primary);
  font-weight: 900;
}

/*  Hero overwrites */
.hero-sinclair-content {
  bottom: 140px;
}

.hero {
  &--simple {
    padding-top: 48px;
    padding-bottom: 16px;
    color: var(--color-primary);

    @media (--viewport-md-min) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    & .hero-head-title {
      margin-top: 0;
    }
  }
  /* Main title color */
  &-head {
    &-title {
      color: var(--color-white);
      text-shadow: 0px 0px 14px var(--color-black);
      @media (--viewport-sm-max) {
        margin-top: 49px;
        font-size: 2.75rem;
        line-height: 2.75rem;
      }
      @media (--viewport-md-min) {
        font-size: 4rem;
        line-height: 4rem;
      }
    }
    &-subtitle {
      color: var(--color-white);
    }
  }
  &-content {
    &__text {
      text-align: left;
      font-weight: 400;
      color: var(--color-white);
    }
  }

  &-fullscreen-media {
    &:before {
      /* Remove background overlay */
      background-image: none;
    }
  }

  &--fullscreen .hero-content {
    /* Buttons overwrites */
    @media (--viewport-sm-max) {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: relative;
      bottom: 0;
      left: 0;
      padding-left: 0px;
      padding-right: 0px;
    }

    & .button {
      @media (--viewport-sm-max) {
        width: 100%;
      }
    }

    /* Cation overwrites */
    &__caption {
      color: var(--color-white);
      bottom: 50px;
      @media (--viewport-sm-max) {
        bottom: -30px;
      }
      &--right {
        @media (--viewport-md-min) {
          transform: translateX(30px);
        }
      }
    }
  }
  &--fullscreen .hero__inner {
    margin-bottom: 40px;
  }

  &-content {
    margin-bottom: 60px;
  }
}

.header,
.header:not(.header--landing-page):not(.header--shadow),
.header--shadow {
  background: var(--color-white);
  color: var(--color-primary);
  background: color(var(--color-white) a(75%));
  box-shadow: none;

  .main-navigation-list-item__link {
    text-shadow: 0 0 8px rgba(255, 255, 255, 1);
  }
}

/* Clinic finder banner overwrites */
.clinic-finder-banner {
  &-media {
    height: 80vh;
    max-height: 750px;

    @media (--viewport-md-min) {
      height: 90vh;
    }
  }

  &-content {
    display: block;
    z-index: 2;

    &__title {
      position: relative;
      color: var(--color-onyx-grey);
      margin: 48px 0 25px 0;
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-onyx-grey);
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
      @media (--viewport-md-min) {
        max-width: 100%;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-white);
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }

    &__description {
      font-size: 1rem;
      color: var(--color-onyx-grey);

      @media (--viewport-md-min) {
        font-size: 1.3rem;
      }
    }

    &-form {
      color: var(--color-black);
      position: absolute;
      top: 50%;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);
      z-index: 2;
      transform: translate3d(0, -50%, 0);
      left: 0;
      width: 100%;
      padding: 64px 32px;
      border-radius: 9px;

      @media (--viewport-sm-min) {
        transform: translate3d(-50%, -50%, 0);
        left: 50%;
        width: auto;
        padding: 64px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--color-primary);
        z-index: -1;
        border-radius: 9px;
      }

      &__input {
        margin-right: 24px;
        padding: 6px 0;
        font-weight: 500;
        border-bottom: 2px solid var(--color-white);
        color: var(--color-white);

        @media (--viewport-md-min) {
          width: 400px;
        }

        &::placeholder {
          color: color(var(--color-white) a(100%));
        }
      }

      &__submit {
        color: var(--color-white);
        top: 50%;
        transform: translate3d(0, -50%, 0);
        right: 10px;

        @media (--viewport-md-min) {
          right: 40px;
        }
      }
    }

    &__cta {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: absolute;
      bottom: 65px;
      left: 0;
      padding-left: var(--container-spacing--mobile);
      padding-right: var(--container-spacing--mobile);

      @media (--viewport-md-min) {
        padding-left: var(--container-spacing);
        padding-right: var(--container-spacing);

        .button {
          width: auto;
        }
      }
    }
  }
}

.location-finder {
  &-page {
    & .header {
      @media (--viewport-md-min) {
        background: var(--color-white);
      }
    }
  }
}

/* Interactive patient preview overwrites */
.interactive-patient-preview {
  &-content {
    z-index: 2;
    h2.interactive-patient-preview-content__title {
      padding-bottom: 25px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background: var(--color-white);
        height: 2px;
        width: 120px;
        border-radius: 24px;
      }
    }
    &__cta {
      bottom: 65px;
    }
    &__caption {
      bottom: 35px;
      @media (--viewport-md-min) {
        bottom: 50px;
      }
    }
  }

  &-media {
    &:before {
      background-image: none;
    }
  }

  &-spots {
    &__item {
      &::before {
        border: none;
        width: 15px;
        height: 15px;
        transform: translate(50%, 50%);
      }
    }
  }

  &-overlay {
    box-shadow: 0px 0px 25px var(--color-onyx-grey);

    &__link {
      &:hover {
        color: var(--color-onyx-grey);
      }
    }
  }
}

/* Gallery image slider */
.select {
  color: var(--color-white);
}

/* Footer overwrites */
.footer {
  position: relative;
  width: 1920px;
  max-width: 100vw;
  margin: auto;

  .social-profiles {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      width: 58px;
      background: var(--color-onyx-grey);
    }
  }
  &-other-brands {
    border-top: 1px solid var(--color-onyx-grey);
    border-bottom: 1px solid var(--color-onyx-grey);
  }

  .copyright {
    color: var(--color-white);
    opacity: 0.8;
  }

  .navigation {
    &-list {
      &-item {
        &__link {
          color: var(--color-white);
        }
      }
    }
  }
}

/* Swirl styles for the footer and hero component */
.ellanse-swirl {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1920px;
  max-width: calc(100vw + 10px);
  transform: rotate(180deg) translate3d(50%, -1%, 0);
  overflow: hidden;
  z-index: 1;
  pointer-events: none;

  & svg {
    position: relative;
    display: block;
    width: calc(134% + 1.3px);
    @media (--viewport-ms-max) {
      height: 40px;
    }
    @media (--viewport-sm-min) {
      height: 70px;
    }
    @media (--viewport-md-min) {
      height: 100px;
    }
  }
  & .shape-fill {
    fill: var(--color-dusky-honey);
  }
  &--top {
    top: 0;
    bottom: unset;
    transform: rotate(0deg) translate3d(-50%, -1%, 0);
  }
}

.social-profiles {
  margin-top: 70px;
}

/* Button overwrites */
.button,
a.button {
  font-weight: 900;
}

/* clinic finder sticky cta overwrites */
.clinic-finder-sticky-cta-popout-form-submit {
  &.button {
    &--secondary {
      color: initial;
      border: none;
    }
    &:hover {
      color: initial;
    }
  }
}

/* FAQ card listing */
.faq-card {
  &__question {
    padding-bottom: 15px;
    margin-bottom: 30px;
    &:after {
      content: '';
      position: absolute;
      width: 120px;
      height: 4px;
      left: 50%;
      bottom: 0;
      background: var(--color-primary);
      transform: translateX(-50%);
      border-radius: 24px;
    }
  }
}

.clinic-finder-landing-page {
  & .button {
    &--chip {
      &:hover {
        background: var(--color-primary);
        color: var(--color-secondary);
      }
    }
  }
}

/* explore-our-specialists overwrites */
.specialist-portrait {
  background-color: var(--color-oaken-bronze);
}

/* explore-our-specialists overwrites */
.faq-list-item-header__label {
  color: var(--color-white);
}

.faq-list-item:not(:last-child):after,
.faq-list-item-header:after,
.faq-list-item-header:before {
  border-color: var(--color-white);
}
