/**
 * Base: Typography
 */

 h1 {
  display: block;
  font-family: var(--heading-font-family), 'sans-serif';
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 2.75rem; /* 44px */
  line-height: 2.75rem; /* 44px */
  letter-spacing: 0.125rem; /* 2px */

  @media (--viewport-sm-min) {
    font-size: 	3.625rem; /* 58px */
    line-height: 	3.625rem; /* 58px */
  }
}

h2 {
  position: relative;
  display: block;
  font-family: var(--heading-font-family);
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.75rem; /* 28px */
  line-height: 2.25rem; /* 36px */

  @media (--viewport-sm-min) {
    font-size: 2.3875rem; /* 35px */
    line-height: 2.8125rem; /* 45px */
  }
}

h3 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.375rem; /* 22px */
  line-height: 1.75rem; /* 28px */

  @media (--viewport-sm-min) {
    font-size: 1.5rem; /* 24px */
    line-height: 1.5rem; /* 24px */
  }

  p + & {
    margin-top: 4rem;
  }
}

h4 {
  display: block;
  font-family: var(--heading-font-family);
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.125rem; /* 18px */
  line-height: 1.125rem; /* 18px */
  letter-spacing: 0.3125rem; /* 5px */
  text-transform: uppercase;

  @media (--viewport-sm-min) {
    font-size: 1.125rem; /* 18px */
    line-height: 1.125rem; /* 18px */
  }
}

p {
  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewport-md-min) {
    font-size: 1.125rem; /* 18px */
  }
}

/* Bold */
b,
strong {
  font-weight: 900;
}

/* Small */
small {
  font-size: 0.875rem; /* 14px */
}

/* Large */
.large {
  font-size: 1.375rem; /* 22px */
  line-height: 2.0625rem; /* 33px */
}